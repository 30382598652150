/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useRef } from "react";
import styles from "./chat.module.css";
import { AssistantStream } from "openai/lib/AssistantStream";
import Markdown from "react-markdown";
// @ts-expect-error - no types for this yet
import { AssistantStreamEvent } from "openai/resources/beta/assistants/assistants";
import { RequiredActionFunctionToolCall } from "openai/resources/beta/threads/runs/runs";
import { AnnotationDelta, TextDelta } from "openai/resources/beta/threads/messages";
import { useAuth } from "./context/AuthContext";
import Select from "./Components/Select";
import { Account, UserState, OnboardingStep, ProcessType, Vertical, Stage } from "./Constants/Type";
import axios from "axios";
import { toast } from "react-toastify";
import { metaApiUrls } from "./utils/metaApi";
import { getConversationStep } from "./utils/conversationManager";
import { ConfirmationFlow } from "./utils/confirmationFlow";
import RadioSelect from "./Components/RadioSelect";
import StageRadioSelect from "./Components/ChatWidgets/StageRadioSelect";

type MessageProps = {
  role: "user" | "assistant" | "code";
  text: string;
};

export const API_URL =
  process.env.REACT_APP_ENV === "production"
    ? "https://flynn-service-1088866048963.europe-west1.run.app"
    : "http://localhost:8080";

const UserMessage = ({ text }: { text: string }) => {
  return <div className={styles.userMessage}>{text}</div>;
};

const AssistantMessage = ({ text }: { text: string }) => {
  return (
    <div className={styles.assistantMessage}>
      <Markdown>{text}</Markdown>
    </div>
  );
};

const CodeMessage = ({ text }: { text: string }) => {
  return (
    <div className={styles.codeMessage}>
      {text.split("\n").map((line, index) => (
        <div key={index}>
          <span>{`${index + 1}. `}</span>
          {line}
        </div>
      ))}
    </div>
  );
};

const Message = ({ role, text }: MessageProps) => {
  switch (role) {
    case "user":
      return <UserMessage text={text} />;
    case "assistant":
      return <AssistantMessage text={text} />;
    case "code":
      return <CodeMessage text={text} />;
    default:
      return null;
  }
};

type ChatProps = {
  functionCallHandler?: (
    toolCall: RequiredActionFunctionToolCall
  ) => Promise<string | undefined>;
  accounts: Account[];
  setAccounts: (accounts: Account[]) => void;
  selectedAccountId: string;
  setSelectedAccountId: (accountId: string) => void;
  userState: UserState;
  userStateLoading: boolean;
};

const Chat = ({
  functionCallHandler = () => Promise.resolve(""),
  accounts,
  setAccounts,
  selectedAccountId,
  setSelectedAccountId,
  userState,
  userStateLoading,
}: ChatProps) => {
  const [userInput, setUserInput] = useState("");
  const [messages, setMessages] = useState<MessageProps[]>([]);
  const [inputDisabled, setInputDisabled] = useState(false);
  const [threadId, setThreadId] = useState("");
  const assistantId = "asst_ZAgXyn4s1ixNzEieuk3rzNMY"; // Hardcoded assistant ID
  const currentConfirmationFlow = useRef<ConfirmationFlow | null>(null);
  const { user, logout, facebookAccessToken, updateUserState } = useAuth();

  const [selectedStage, setSelectedStage] = useState<Stage>(Stage.UNKNOWN);


  // automatically scroll to bottom of chat
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // New useEffect to create a thread when the component mounts
  useEffect(() => {
    createThread();
  }, []);

  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        const response = await axios.get(metaApiUrls.getFacebookPages(), {
          params: { access_token: facebookAccessToken },
        });

        const accountsWithInstagram = await Promise.all(
          response.data.data.map(async (account: Account) => {
            const instagramResponse = await axios.get(
              metaApiUrls.getFacebookPageInstagram(account.id),
              {
                params: { access_token: facebookAccessToken },
              }
            );
            return {
              ...account,
              instagram_business_account:
                instagramResponse.data.instagram_business_account,
            };
          }),
        );

        setAccounts(accountsWithInstagram);
      } catch (error) {
        toast.error("There was an issue authenticating your Facebook account.");
        console.log("Error fetching accounts:", error);
      }
    };

    if (facebookAccessToken) {
      fetchAccounts();
    }
  }, [facebookAccessToken]);

  useEffect(() => {
    // only update messages once user state is loaded so we use correct conversation step
    if (userStateLoading) return;

    // get the conversation step based on the users state
    // this could be ONBOARDING where we ask back and forth questions using the conversation manager
    // or it could be NONE where we just send messages to the LLM Assistant API for normal chat
    const conversationStep = getConversationStep(
      userState.process.type,
      userState.process.step,
      userState
    );
    
    if (conversationStep) {
      setMessages(prevMessages => {
        const lastMessages = prevMessages.slice(-conversationStep.messages.length);
        const newMessagesAsString = JSON.stringify(conversationStep.messages);
        const lastMessagesAsString = JSON.stringify(lastMessages);
        
        if (lastMessagesAsString !== newMessagesAsString) {
          return [...prevMessages, ...conversationStep.messages];
        }
        return prevMessages;
      });
    }
  }, [userState?.process.type, userState?.process.step, userState, userStateLoading]);

  const createThread = async () => {
    try {
      const token = await user?.getIdToken();
      const res = await fetch(`${API_URL}/api/create-thread`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        }
      });
      const data = await res.json();
      setThreadId(data.threadId);
    } catch (error) {
      console.error("Error creating thread:", error);
    }
  };

  const sendMessage = async (text: string) => {
    if (!threadId || !assistantId) {
      console.error("No thread ID or assistant ID available");
      return;
    }
    try {
      const token = await user?.getIdToken();

      const response = await fetch(
        `${API_URL}/api/threads/${threadId}/messages`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
          body: JSON.stringify({
            content: text,
            assistantId: assistantId,
          }),
        }
      );
      if (response.body === null) {
        console.error("No response body");
        return;
      }
    
      const reader = response.body.getReader();
      const stream = new ReadableStream({
        async start(controller) {
          while (true) {
            const { done, value } = await reader.read();
            if (done) break;
            controller.enqueue(value);
          }
          controller.close();
        },
      });

      const assistantStream = AssistantStream.fromReadableStream(stream);
      handleReadableStream(assistantStream);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const submitActionResult = async (runId: string, toolCallOutputs: any) => {
    try {
      const token = await user?.getIdToken();

      const response = await fetch(
        `${API_URL}/api/threads/${threadId}/submit-tool-outputs`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
          body: JSON.stringify({
            runId: runId,
            toolCallOutputs: toolCallOutputs,
          }),
        }
      );
      if (response.body === null) {
        // todo: handle error
        return
      }
      const stream = AssistantStream.fromReadableStream(response.body);
      handleReadableStream(stream);
    } catch (error) {
      console.error("Error submitting tool outputs:", error);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!userInput.trim()) return;

    setMessages((prevMessages) => [
      ...prevMessages,
      { role: "user", text: userInput },
    ]);

    setInputDisabled(true);
    scrollToBottom();

    if (userState?.process.type === ProcessType.NONE) {
      sendMessage(userInput);
    } else {
      const conversationStep = getConversationStep(
        userState.process.type,
        userState.process.step,
        userState
      );

      if (conversationStep) {
        if (conversationStep.createConfirmationFlow) {
          // Create flow only if it doesn't exist
          const flow = currentConfirmationFlow.current || conversationStep.createConfirmationFlow();
          if (!currentConfirmationFlow.current) {
            currentConfirmationFlow.current = flow;
          }
          
          const result = await flow.processInput(userInput, user);
          
          setMessages((prevMessages) => [
            ...prevMessages,
            ...result.messages
          ]);

          if (result.isComplete && result.confirmedValue) {
            await conversationStep.handleResponse(result.confirmedValue, updateUserState);
            console.log("Clearing confirmation flow");
            currentConfirmationFlow.current = null; // Clear the flow when complete
          }
        } else {
          const aiResponse = await conversationStep.processResponse(userInput, user);
          
          if (!aiResponse.isValid) {
            const errorMessage = aiResponse.errorMessage || 
              conversationStep.errorMessages[0];
            setMessages((prevMessages) => [
              ...prevMessages,
              { role: "assistant", text: errorMessage },
            ]);
          } else {
            await conversationStep.handleResponse(aiResponse.extractedData, updateUserState);
          }
        }
        setInputDisabled(false);
      }
    }

    setUserInput("");
  };

  /* Stream Event Handlers */

  // textCreated - create new assistant message
  const handleTextCreated = () => {
    appendMessage("assistant", "");
  };

  // textDelta - append text to last assistant message
  const handleTextDelta = (delta: TextDelta) => {
    if (delta.value != null) {
      appendToLastMessage(delta.value);
    };
    if (delta.annotations != null) {
      annotateLastMessage(delta.annotations);
    }
  };

  // // toolCallCreated - log new tool call
  // const toolCallCreated = (toolCall) => {
  //   if (toolCall.type != "code_interpreter") return;
  //   appendMessage("code", "");
  // };

  // // toolCallDelta - log delta and snapshot for the tool call
  // const toolCallDelta = (delta, snapshot) => {
  //   if (delta.type != "code_interpreter") return;
  //   if (!delta.code_interpreter.input) return;
  //   appendToLastMessage(delta.code_interpreter.input);
  // };

  // handleRequiresAction - handle function call
  const handleRequiresAction = async (
    event: AssistantStreamEvent.ThreadRunRequiresAction
  ) => {
    const runId = event.data.id;
    const toolCalls = event.data.required_action.submit_tool_outputs.tool_calls;
    // loop over tool calls and call function handler
    const toolCallOutputs = await Promise.all(
      toolCalls.map(async (toolCall: RequiredActionFunctionToolCall) => {
        const result = await functionCallHandler(toolCall);
        return { output: result, tool_call_id: toolCall.id };
      })
    );
    setInputDisabled(true);
    submitActionResult(runId, toolCallOutputs);
  };

  // handleRunCompleted - re-enable the input form
  const handleRunCompleted = () => {
    setInputDisabled(false);
  };

  const handleReadableStream = (stream: AssistantStream) => {
    // messages
    stream.on("textCreated", handleTextCreated);
    stream.on("textDelta", handleTextDelta);

    // image
    // stream.on("imageFileDone", handleImageFileDone);

    // code interpreter
    // stream.on("toolCallCreated", toolCallCreated);
    // stream.on("toolCallDelta", toolCallDelta);

    // events without helpers yet (e.g. requires_action and run.done)
    stream.on("event", (event) => {
      if (event.event === "thread.run.requires_action")
        handleRequiresAction(event);
      if (event.event === "thread.run.completed") handleRunCompleted();
    });
  };

  /*
    =======================
    === Utility Helpers ===
    =======================
  */

  const appendToLastMessage = (text: string) => {
    setMessages((prevMessages) => {
      const lastMessage = prevMessages[prevMessages.length - 1];
      const updatedLastMessage = {
        ...lastMessage,
        text: lastMessage.text + text,
      };
      return [...prevMessages.slice(0, -1), updatedLastMessage];
    });
  };

  const appendMessage = (role: "user" | "assistant" | "code", text: string) => {
    setMessages((prevMessages) => [...prevMessages, { role, text }]);
  };

  const resetUserState = () => {
    updateUserState({
      info: {
        name: "",
        verticals: [],
        platforms: [],
        stage: Stage.NEW,
      },
      process: {
        type: ProcessType.ONBOARDING,
        step: OnboardingStep.INTRO,
      },
    });
  }


  const annotateLastMessage = (annotations: AnnotationDelta[]) => {
    setMessages((prevMessages) => {
      const lastMessage = prevMessages[prevMessages.length - 1];
      const updatedLastMessage = {
        ...lastMessage,
      };
      annotations.forEach((annotation) => {
        if (annotation.type === 'file_path' && annotation.text && annotation.file_path?.file_id) {
          updatedLastMessage.text = updatedLastMessage.text.replaceAll(
            annotation.text,
            `/api/files/${annotation.file_path.file_id}`
          );
        }
      });
      return [...prevMessages.slice(0, -1), updatedLastMessage];
    });
    
  }

  return (
    <div className={styles.chatContainer}>
      <div css={{ display: "flex" }}>
            <Select
              value={selectedAccountId}
              onChange={(e) => setSelectedAccountId(e.target.value)}
              placeholder="Select an account"
              options={accounts.map((account) => ({
                value: account.id,
                label: `${account.name} ${
                  account.instagram_business_account
                    ? "(Instagram connected)"
                    : ""
                }`,
              }))}
            />
                  <button 
              className={styles.logoutButton} 
              onClick={logout}
            >
              Logout
            </button>
            {/* <button 
              className={styles.fetchInsightsButton} 
              onClick={fetchInsights}
            >
              Fetch Insights
            </button> */}
      </div>
      <div>
        <button className={styles.logoutButton}  onClick={resetUserState}>Reset Onboarding</button>
        {/* <StageRadioSelect value={selectedStage} onChange={setSelectedStage} /> */}
      </div>
      {threadId && (
        <>
          <div className={styles.messages}>
            {messages.map((msg, index) => (
              <Message key={index} role={msg.role} text={msg.text} />
            ))}
            <div ref={messagesEndRef} />
          </div>
          <form
            onSubmit={handleSubmit}
            className={`${styles.inputForm} ${styles.clearfix}`}
          >
            <input
              type="text"
              className={styles.input}
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              placeholder={!selectedAccountId ? "Please select an account first" : "Enter your question"}
              disabled={inputDisabled || !selectedAccountId}
            />
            <button
              type="submit"
              className={styles.button}
              disabled={inputDisabled || !selectedAccountId}
            >
              Send
            </button>
          </form>
        </>
      )}
    </div>
  );
};

export default Chat;
