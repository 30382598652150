import { ProcessType, OnboardingStep, UserState, Stage, Vertical, Platform } from '../Constants/Type';
import { User } from 'firebase/auth';
import axios from 'axios';
import { ConfirmationFlow } from './confirmationFlow';

type Message = {
  role: 'assistant' | 'user';
  text: string;
};

export const API_URL =
  process.env.REACT_APP_ENV === "production"
    ? "https://flynn-service-1088866048963.europe-west1.run.app"
    : "http://localhost:8080";

type AIResponse = {
  isValid: boolean;
  extractedData?: any;
  errorMessage?: string;
};

type ConversationStep = {
  messages: Message[];
  errorMessages: string[];
  createConfirmationFlow?: () => ConfirmationFlow;
  processResponse: (
    response: string,
    user: User | null
  ) => Promise<AIResponse>;
  handleResponse: (
    extractedData: any,
    updateUserState: (updates: Partial<UserState>) => Promise<void>
  ) => Promise<void>;
};

export const getConversationFlow = (userState: UserState) => ({
  [ProcessType.ONBOARDING]: {
    [OnboardingStep.INTRO]: {
      messages: [
        { role: 'assistant', text: 'Hello!' },
        { role: 'assistant', text: "I'm Flynn, your personal creator assistant!" },
        { role: 'assistant', text: "First, let's get to know each other." },
        { role: 'assistant', text: 'What should I call you?' },
      ],
      errorMessages: [
        "I couldn't quite catch your name. Could you please try again?",
      ],
      createConfirmationFlow: () => new ConfirmationFlow({
        messages: {
          askConfirmation: (data) => `Is ${data.name} correct?`,
          askNewValue: "What should I call you instead?",
          error: ["I couldn't quite catch your name. Could you please try again?"]
        },
        processResponse: async (response, user) => {
          const aiResponse = await generateCompletion(
            `Extract a name from this response. 
             If a valid name is found, return the following JSON with the first letter capitalised (e.g. John): {"isValid": true, "extractedData": {"name": "EXTRACTED_NAME"}}
             If no valid name is found, return: {"isValid": false}
             Response: ${response}`,
            user
          );
          return JSON.parse(aiResponse);
        },
        validateConfirmation: async (response, previousValue, user) => {
          const aiResponse = await generateCompletion(
            `Analyze this response to a name confirmation.
             Previous name: "${previousValue.name}"
             If it's a confirmation (yes/yeah/correct/etc), return: {"isConfirmed": true}
             If it contains a new name (e.g. "no, it's John"), return: {"isConfirmed": false, "newValue": {"name": "NEW_NAME"}}
             If it's just a rejection (no/nope/incorrect/etc), return: {"isConfirmed": false}
             Response: ${response}`,
            user
          );
          return JSON.parse(aiResponse);
        }
      }),
      handleResponse: async (extractedData: { name: string }, updateUserState: (updates: Partial<UserState>) => Promise<void>) => {
        await updateUserState({
          info: {
            name: extractedData.name,
            verticals: [],
            platforms: [],
            stage: Stage.UNKNOWN,
          },
          process: {
            type: ProcessType.ONBOARDING,
            step: OnboardingStep.STAGE,
          },
        });
      },
    },
    [OnboardingStep.STAGE]: {
      messages: [
        { role: 'assistant', text: `Great to meet you ${userState.info.name}!` },
        {
          role: 'assistant',
          text: 'Where would you say you are in your creator journey?',
        },
        {
          role: 'assistant',
          text: '1. Just starting out\n2. Growing my audience\n3. Established creator\n4. Professional creator',
        },
      ],
      errorMessages: [
        "I didn't understand your stage selection. Please choose a number between 1 and 4.",
      ],
      processResponse: async (response: string, user: User | null) => {
        const aiResponse = await generateCompletion(
          `We have given the user a list of stages: NEW, EARLY, BEGINNER, INTERMEDIATE, ESTABLISHED, PRO. Extract the selected stage from their response. If valid, return: {"isValid": true, "extractedData": {"stage": "SELECTED_STAGE"}}. If invalid, return: {"isValid": false, "errorMessage": "I didn't understand your stage selection. Please choose a number between 1 and 4."}. Response: ${response}`,
          user
        );
        return JSON.parse(aiResponse);
      },
      handleResponse: async (extractedData: { stage: string }, updateUserState: (updates: Partial<UserState>) => Promise<void>) => {
        await updateUserState({
          info: {
            ...userState.info,
            stage: extractedData.stage as Stage,
          },
          process: {
            type: ProcessType.ONBOARDING,
            step: OnboardingStep.VERTICALS,
          },
        });
      },
    },
    [OnboardingStep.VERTICALS]: {
      messages: [
        { role: 'assistant', text: 'What verticals are you interested in?' },
        { role: 'assistant', text: '1. Fitness\n2. Photography\n3. Food' },
      ],
      errorMessages: [
        "I couldn't understand your vertical selection. Please choose from Fitness, Photography, or Food.",
        "Sorry, I need valid verticals to continue. Can you try again with Fitness, Photography, or Food?",
      ],
      processResponse: async (response: string, user: User | null) => {
        const aiResponse = await generateCompletion(
          `Extract the verticals from this response. Valid verticals are FITNESS, PHOTOGRAPHY, FOOD. If valid verticals are found, return them in JSON format: {"isValid": true, "extractedData": {"verticals": ["VERTICAL1", "VERTICAL2"]}}. If no valid verticals are found, return: {"isValid": false, "errorMessage": "I couldn't understand your vertical selection. Please choose from Fitness, Photography, or Food."}. Response: ${response}`,
          user
        );
        return JSON.parse(aiResponse);
      },
      createConfirmationFlow: () => new ConfirmationFlow({
        messages: {
          askConfirmation: (data) => `Is ${data.verticals} correct?`,
          askNewValue: "What verticals should I choose instead?",
          error: ["I couldn't quite understand your verticals selection. Could you please try again?"]
        },
        processResponse: async (response, user) => {
          const aiResponse = await generateCompletion(
            `Extract the verticals from this response. Valid verticals are FITNESS, PHOTOGRAPHY, FOOD.
             If valid verticals are found, return the following JSON with the verticals in their valid uppercaseformat (e.g. FITNESS): {"isValid": true, "extractedData": {"verticals": ["VERTICAL1", "VERTICAL2"]}}
             If no valid verticals are found, return: {"isValid": false}
             Response: ${response}`,
            user
          );
          return JSON.parse(aiResponse);
        },
        validateConfirmation: async (response, previousValue, user) => {
          const aiResponse = await generateCompletion(
            `Valid verticals: FITNESS, PHOTOGRAPHY, FOOD.
             Verticals suggested to the user: ${previousValue.verticals.join(", ")}

             We have given the user a list of suggested verticals and asked them to confirm. Analyze this response to a verticals confirmation.
             If it's a confirmation (yes/yeah/correct/etc), return: {"isConfirmed": true}
             If it contains adjusted verticals (e.g. "no, it's Fitness and Photography" or "Add Photography as well"), return: {"isConfirmed": false, "newValue": {"verticals": ["NEW_VERTICAL1", "NEW_VERTICAL2"]}}
             If it's just a rejection (no/nope/incorrect/etc), return: {"isConfirmed": false}
             User response to analyse: ${response}`,
            user
          );
          return JSON.parse(aiResponse);
        }
      }),
      handleResponse: async (extractedData: { verticals: Vertical[] }, updateUserState: (updates: Partial<UserState>) => Promise<void>) => {
        await updateUserState({
          info: {
            ...userState.info,
            verticals: extractedData.verticals,
          },
          process: {
            type: ProcessType.ONBOARDING,
            step: OnboardingStep.PLATFORMS,
          },
        });
      },
    },
    [OnboardingStep.PLATFORMS]: {
      messages: [
        { role: 'assistant', text: 'What platforms are you interested in?' },
        { role: 'assistant', text: '1. Instagram\n2. YouTube\n3. TikTok' },
      ],
      errorMessages: [
        "I couldn't understand your platform selection. Please choose from Instagram, YouTube, or TikTok.",
        "Sorry, I need valid platforms to continue. Can you try again with Instagram, YouTube, or TikTok?",
      ],
      processResponse: async (response: string, user: User | null) => {
        const aiResponse = await generateCompletion(
          `Extract the platforms from this response. Valid platforms are INSTAGRAM, YOUTUBE, TIKTOK. If valid platforms are found, return them in JSON format: {"isValid": true, "extractedData": {"platforms": ["PLATFORM1", "PLATFORM2"]}}. If no valid platforms are found, return: {"isValid": false, "errorMessage": "I couldn't understand your platform selection. Please choose from Instagram, YouTube, or TikTok."}. Response: ${response}`,
          user
        );
        return JSON.parse(aiResponse);
      },
      handleResponse: async (extractedData: { platforms: Platform[] }, updateUserState: (updates: Partial<UserState>) => Promise<void>) => {
        await updateUserState({
          info: {
            ...userState.info,
            platforms: extractedData.platforms,
          },
          process: {
            type: ProcessType.NONE,
            step: null,
          },  
        });
      },
    },
    // [OnboardingStep.POSTING_WEEKLY_FREQUENCY]: {
    //   messages: [
    //     { role: 'assistant', text: 'How many posts per week do you aim to publish?' },
    //   ],
    //   handleResponse: async (
    //     response: string, 
    //     user: User | null,
    //     updateUserState: (updates: Partial<UserState>) => Promise<void>
    //   ) => {

    //   },
    // },
  },
  [ProcessType.NONE]: {
    messages: [
      { 
        role: 'assistant', 
        text: "Hi there " + userState.info.name + "! How can I help you today?" 
      }
    ]
  }
});

export const getConversationStep = (
  processType: ProcessType,
  step: string | null,
  userState: UserState
): ConversationStep | undefined => {
  const flow = getConversationFlow(userState);
  if (processType === ProcessType.NONE) {
    return flow[ProcessType.NONE] as ConversationStep;
  }
  return flow[processType]?.[step as OnboardingStep] as ConversationStep;
};

async function generateCompletion(prompt: string, user: User | null): Promise<string> {
  try {
    const token = await user?.getIdToken();

    const { data } = await axios.post(`${API_URL}/api/completions`, { prompt }, {
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      }
    });
    return data.completion;
  } catch (error) {
    console.error('Error generating completion:', error);
    return ''; // Return empty string on error, or handle error differently as needed
  }
}
