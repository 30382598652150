import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyC9B0eG8x5-gN04A7LkzbCRsriSGYx4xh0",
  authDomain: "flynn-web.firebaseapp.com",
  projectId: "flynn-web",
  storageBucket: "flynn-web.appspot.com",
  messagingSenderId: "1088866048963",
  appId: "1:1088866048963:web:0e3388a7b94c8a63603b76",
  measurementId: "G-6XMBB0TD3S",
  databaseURL: "https://flynn-web-default-rtdb.europe-west1.firebasedatabase.app"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const database = getDatabase(app);

export { app, analytics, auth, googleProvider, database };
