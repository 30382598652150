import { User } from "firebase/auth";

type AIResponse = {
  isValid: boolean;
  extractedData?: any;
  errorMessage?: string;
};

type Message = {
  role: 'assistant' | 'user';
  text: string;
};
  
type ConfirmationState = {
    status: 'INITIAL' | 'AWAITING_CONFIRMATION' | 'AWAITING_NEW_VALUE';
    currentValue: any;
  };
  
  type ConfirmationConfig = {
    messages: {
      askConfirmation: (value: any) => string;
      askNewValue: string;
      error: string[];
    };
    processResponse: (response: string, user: User | null) => Promise<AIResponse>;
    validateConfirmation: (response: string, previousValue: any, user: User | null) => Promise<{
      isConfirmed: boolean;
      newValue?: any;
    }>;
  };
  
  export class ConfirmationFlow {
    private state: ConfirmationState;
    private config: ConfirmationConfig;
  
    constructor(config: ConfirmationConfig) {
      this.state = {
        status: 'INITIAL',
        currentValue: null
      };
      this.config = config;
    }
  
    async processInput(response: string, user: User | null): Promise<{
      messages: Message[];
      isComplete?: boolean;
      confirmedValue?: any;
    }> {
      console.log('Current state:', this.state.status, 'Processing:', response);
  
      switch (this.state.status) {
        case 'INITIAL': {
          const result = await this.config.processResponse(response, user);
          if (!result.isValid) {
            return {
              messages: [{ role: 'assistant', text: this.config.messages.error[0] }]
            };
          }
  
          this.state = {
            status: 'AWAITING_CONFIRMATION',
            currentValue: result.extractedData
          };
  
          return {
            messages: [{
              role: 'assistant',
              text: this.config.messages.askConfirmation(result.extractedData)
            }]
          };
        }
  
        case 'AWAITING_CONFIRMATION': {
          const confirmResult = await this.config.validateConfirmation(
            response, 
            this.state.currentValue,
            user
          );
          console.log('Confirmation result:', confirmResult);
          
          if (confirmResult.isConfirmed) {
            return {
              messages: [{ 
                role: 'assistant', 
                text: 'Great! Moving forward...' 
              }],
              isComplete: true,
              confirmedValue: this.state.currentValue
            };
          }
  
          if (confirmResult.newValue) {
            this.state = {
              status: 'AWAITING_CONFIRMATION',
              currentValue: confirmResult.newValue
            };
            return {
              messages: [{
                role: 'assistant',
                text: this.config.messages.askConfirmation(confirmResult.newValue)
              }]
            };
          }
  
          this.state = {
            status: 'AWAITING_NEW_VALUE',
            currentValue: this.state.currentValue
          };
          return {
            messages: [{
              role: 'assistant',
              text: this.config.messages.askNewValue
            }]
          };
        }
  
        case 'AWAITING_NEW_VALUE': {
          const result = await this.config.processResponse(response, user);
          if (result.isValid) {
            this.state = {
              status: 'AWAITING_CONFIRMATION',
              currentValue: result.extractedData
            };
            return {
              messages: [{
                role: 'assistant',
                text: this.config.messages.askConfirmation(result.extractedData)
              }]
            };
          }
  
          return {
            messages: [{ role: 'assistant', text: this.config.messages.error[0] }]
          };
        }
      }
    }
  }