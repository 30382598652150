/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { Colours } from "../Constants/Colours";
import { FontSizes, FontWeight } from "../Constants/Type";
import GreyArrowDown from "../Images/DownGreyArrow.svg";

interface SelectProps {
  value?: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  options: { value: string; label: string }[];
  placeholder?: string;
  disabled?: boolean;
}

const selectContainerStyles = css`
  position: relative;
  display: inline-block;
  width: 100%;
`;

const selectStyles = css`
  font-family: "Roboto", sans-serif;
  padding: 10px 16px;
  font-size: ${FontSizes.medium};
  font-weight: ${FontWeight.regular};
  background-color: ${Colours.propertyBackground};
  border-radius: 20px;
  width: 100%;
  box-sizing: border-box;
  transition: background-color 0.3s ease;
  outline: none;
  border: none;
  height: 40px;
  appearance: none;
  padding-right: 40px; /* Add space for the arrow */
  cursor: none;

  &:hover {
    background-color: ${Colours.background};
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const arrowStyles = css`
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  pointer-events: none;
  display: flex;
  align-items: center;
`;

const Select: React.FC<SelectProps> = ({
  value,
  onChange,
  options,
  placeholder,
  disabled,
}) => {
  return (
    <div css={selectContainerStyles}>
      <select
        css={selectStyles}
        value={value}
        onChange={onChange}
        disabled={disabled}
      >
        {placeholder && (
          <option value="" disabled>
            {placeholder}
          </option>
        )}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <div css={arrowStyles}>
        <img src={GreyArrowDown} alt="Arrow Down" />
      </div>
    </div>
  );
};

export default Select;
