import React from 'react';
import { useAuth } from '../context/AuthContext';

const Auth: React.FC = () => {
  const { isAuthenticated, user, loginWithGoogle, loginWithFacebook, logout } = useAuth();

  return (
    <div>
      {isAuthenticated ? (
        <div>
          <p>Welcome, {user?.displayName}!</p>
          <button onClick={logout}>Logout</button>
        </div>
      ) : (
        <>
          <button onClick={loginWithGoogle}>Login with Google</button>
          <button onClick={loginWithFacebook}>Login with Facebook</button>
        </>
      )}
    </div>
  );
};

export default Auth;
