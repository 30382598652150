import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import { auth, database, googleProvider } from '../firebase';
import { signInWithPopup, signOut, User, FacebookAuthProvider, onAuthStateChanged } from 'firebase/auth';
import { get, ref, update } from 'firebase/database';
import axios from 'axios';
import { toast } from 'react-toastify';
import { OnboardingStep, ProcessType, Stage, UserState } from '../Constants/Type';

interface AuthContextType {
  user: User | null;
  isAuthenticated: boolean;
  facebookAccessToken: string | null;
  userState: UserState;
  userStateLoading: boolean;
  loginWithGoogle: () => Promise<void>;
  loginWithFacebook: () => Promise<void>;
  logout: () => Promise<void>;
  getToken: () => Promise<string | null>;
  fetchUserState: () => Promise<void>;
  updateUserState: (updates: Partial<UserState>) => Promise<void>;
  loading: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const FB_API_URL = "https://graph.facebook.com/v20.0";

const checkTokenValidity = async (token: string) => {
  try {
    const response = await axios.get(`${FB_API_URL}/debug_token`, {
      params: {
        input_token: token,
        access_token: token, // We use the same token as both input and access token
      },
    });

    const data = response.data.data;

    // Check if the token is valid and not expired
    return data.is_valid;
  } catch (error) {
    console.error("Error checking token validity:", error);
    return false;
  }
};

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [facebookAccessToken, setFacebookAccessToken] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [userState, setUserState] = useState<UserState>({
      goals: {
        targetPostsPerWeek: 0
      },
      info: {
        name: "",
        verticals: [],
        platforms: [],
        stage: Stage.NEW,
      },
      process: {
        type: ProcessType.ONBOARDING,
        step: OnboardingStep.INTRO,
      },
  });
  const [userStateLoading, setUserStateLoading] = useState(true);

  const handleInvalidToken = async () => {
    console.log("Facebook token is invalid. Logging out...");
    setFacebookAccessToken(null);
    await auth.signOut();
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user: User | null) => {
      setUser(user);
      if (user) {
        const userRef = ref(database, `users/${user.uid}`);
        const fetchToken = async (retries = 5) => {
          const snapshot = await get(userRef);
          if (snapshot.exists()) {
            const userData = snapshot.val();
            if (userData.facebookAccessToken) {
              const isValid = await checkTokenValidity(
                userData.facebookAccessToken,
              );
              if (isValid) {
                setFacebookAccessToken(userData.facebookAccessToken);
              } else if (retries > 0) {
                console.log("Token invalid. Retrying...");
                setTimeout(() => fetchToken(retries - 1), 1000);
              } else {
                await handleInvalidToken();
              }
            } else if (retries > 0) {
              console.log("No token found. Retrying...");
              setTimeout(() => fetchToken(retries - 1), 1000);
            } else {
              setFacebookAccessToken(null);
            }
          } else {
            setFacebookAccessToken(null);
          }
        };
        fetchToken();
      } else {
        setFacebookAccessToken(null);
      }
      setIsAuthenticated(user !== null);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    if (user) {
      fetchUserState();
    }
  }, [user]);

  const loginWithGoogle = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
    } catch (error: any) {
      if (error.code === "auth/admin-restricted-operation") {
        toast.error(
          "New user registration is currently restricted. Please contact the administrator.",
        );
      } else {
        toast.error("An error occurred while signing in. Please try again.");
      }
    }
  };

  const loginWithFacebook = async () => {
    const provider = new FacebookAuthProvider();
    provider.addScope(
      "instagram_basic,pages_show_list,business_management,pages_read_engagement,instagram_manage_insights",
    );
    try {
      const result = await signInWithPopup(auth, provider);
      const credential = FacebookAuthProvider.credentialFromResult(result);
      const token = credential?.accessToken;

      if (token && auth.currentUser) {
        const userRef = ref(database, `users/${auth.currentUser.uid}`);
        await update(userRef, { facebookAccessToken: token });
      }
    } catch (error: any) {
      console.error("Error signing in with Facebook:", error);
      if (error.code === "auth/admin-restricted-operation") {
        toast.error(
          "New user registration is currently restricted. Please contact the administrator.",
        );
      } else {
        toast.error("An error occurred while signing in. Please try again.");
      }
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Error signing out', error);
    }
  };

  const getToken = async () => {
    if (user) {
      return await user.getIdToken();
    }
    return null;
  };

  const fetchUserState = async () => {
    if (!user) return;
    
    setUserStateLoading(true);
    try {
      const userRef = ref(database, `users/${user.uid}/state`);
      const snapshot = await get(userRef);
      
      if (snapshot.exists()) {
        setUserState(snapshot.val());
      } else {
        // Initialize default user state if none exists
        const defaultState: UserState = {
          goals: {
            targetPostsPerWeek: 0
          },
          info: {
            name: "",
            verticals: [],
            platforms: [],
            stage: Stage.NEW,
          },
          process: {
            type: ProcessType.ONBOARDING,
            step: OnboardingStep.INTRO,
          },
        };
        await update(userRef, defaultState);
        setUserState(defaultState);
      }
    } catch (error) {
      console.error('Error fetching user state:', error);
      toast.error('Failed to fetch user settings');
    } finally {
      setUserStateLoading(false);
    }
  };

  const updateUserState = async (updates: Partial<UserState>) => {
    if (!user) return;
    
    try {
      const userRef = ref(database, `users/${user.uid}/state`);
      await update(userRef, updates);
      
      // Get the complete updated state
      const snapshot = await get(userRef);
      const newState = snapshot.exists() ? snapshot.val() : updates;
      
      setUserState(newState);
    } catch (error) {
      console.error("Error updating user state:", error);
      toast.error("Failed to update user state");
    }
  };

  return (
    <AuthContext.Provider 
      value={{ 
        user, 
        isAuthenticated, 
        facebookAccessToken,
        userState,
        userStateLoading,
        loading,
        loginWithGoogle, 
        loginWithFacebook, 
        logout, 
        getToken,
        fetchUserState,
        updateUserState
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
