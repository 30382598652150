const FontSizes = {
  small: "12px",
  medium: "14px",
  large: "16px",
};

const FontWeight = {
  regular: 400,
  medium: 500,
};

export interface Account {
  id: string;
  name: string;
  instagram_business_account?: {
    id: string;
  };
}

export enum ProcessType {
  NONE = "NONE",
  ONBOARDING = "ONBOARDING",
  // Add other process types here as needed
}

export enum OnboardingStep {
  INTRO = "INTRO",
  STAGE = "STAGE",
  VERTICALS = "VERTICALS",
  PLATFORMS = "PLATFORMS",
  // POSTING_WEEKLY_FREQUENCY = "POSTING_WEEKLY_FREQUENCY",
}

export type ProcessStepMap = {
  [ProcessType.ONBOARDING]: OnboardingStep;
  [ProcessType.NONE]: null;
}

export interface Process<T extends ProcessType> {
  type: T;
  step: ProcessStepMap[T];
}

export enum Platform {
  INSTAGRAM = "INSTAGRAM",
  YOUTUBE = "YOUTUBE",
  TIKTOK = "TIKTOK",
}

export enum Vertical {
  FITNESS = "FITNESS",
  PHOTOGRAPHY = "PHOTOGRAPHY",
  FOOD = "FOOD",
}

export enum Stage {
  UNKNOWN = "UNKNOWN",
  NEW = "NEW",
  BEGINNER = "BEGINNER",
  INTERMEDIATE = "INTERMEDIATE",
  PRO = "PRO"
}

// map each stage to a string - First letter capital - hard code the values

export const stageToString = (stage: Stage): string | null => {
  switch (stage) {
    case Stage.UNKNOWN: return null;
    case Stage.NEW: return "New";
    case Stage.BEGINNER: return "Beginner";
    case Stage.INTERMEDIATE: return "Intermediate";
    case Stage.PRO: return "Pro";
  }
}

// description for each stage
export const stageDescription = (stage: Stage): string | null => {
  switch (stage) {
    case Stage.UNKNOWN: return null;
    case Stage.NEW: return "You're just starting out and exploring what you can create.";
    case Stage.BEGINNER: return "You've been creating content for a while and are looking to grow your audience.";
    case Stage.INTERMEDIATE: return "You're an established creator with a growing audience.";
    case Stage.PRO: return "You're a professional creator with a large audience.";
  }
}

export interface Info {
  name: string;
  verticals: Vertical[];
  platforms: Platform[];
  stage: Stage;
}

export interface Goals {
  targetPostsPerWeek: number;
}

export interface UserState {
  goals: Goals;
  process: Process<ProcessType>;
  info: Info;
}

export const FB_API_URL = "https://graph.facebook.com/v20.0";

export { FontSizes, FontWeight };