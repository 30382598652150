import { ref, get, update } from 'firebase/database';
import { database } from '../firebase';
import { UserState } from '../Constants/Type';
import { User } from 'firebase/auth';

export const updateState = async (user: User | null, updates: Partial<UserState>) => {
  if (!user) {
    return { error: "User not authenticated" };
  }

  try {
    const userRef = ref(database, `users/${user.uid}/state`);
    await update(userRef, updates);
    
    // Get the complete updated state
    const snapshot = await get(userRef);
    const newState = snapshot.exists() ? snapshot.val() : updates;
    
    return { success: true, state: newState };
  } catch (error) {
    console.error("Error updating user state:", error);
    return { error: "Failed to update user state" };
  }
};

export const fetchState = async (user: User | null) => {
  if (!user) {
    return { error: "User not authenticated" };
  }
  
  try {
    const userRef = ref(database, `users/${user.uid}/state`);
    const snapshot = await get(userRef);
    
    if (snapshot.exists()) {
      console.log("user state found");
      console.log(snapshot.val());
      return snapshot.val();
    }

  } catch (error) {
    console.error("Error fetching user state:", error);
    return { error: "Failed to fetch user state" };
  }
};
