const Colours = {
  primary: "#5F72E8",
  secondary: "#354082",
  background: "#E5E5E5",
  propertyBackground: "#F2F2F2",
  propertyForeground: "#D7D7D7",
  foreground: "#FCFCFC",
  black: "#000000",
};

const Gradients = {
  primary: `linear-gradient(to left, ${Colours.primary}, ${Colours.secondary})`,
};

const Shadows = {
  primary: "2px 2px 10px 0px rgba(0, 0, 0, 0.1)",
  canvas: "7px 7px 15px 0px rgba(0, 0, 0, 0.25)",
};

export { Colours, Gradients, Shadows };
